var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-manage-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("项目信息")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { attrs: { title: "项目信息" } },
            [
              _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME }),
              _c(
                "div",
                { staticClass: "save-button" },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        type: "primary",
                        "click-prop": _vm.saveClick.bind(this),
                      },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }