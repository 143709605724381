





















import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@common-src/mixins/base-component';
import { ProjectEntityModel } from '@common-src/entity-model/project-entity';
import ProjectService from '@common-src/service/project';
import { UserStoreModule } from '@common-src/store/modules/user';
import FormComponent from '@common-src/mixins/form-component';
import { ViewModeType } from '@common-src/model/enum';

@Component
export default class ProjectManageComponent extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<ProjectEntityModel>;

    mounted() {
        this.jtlForm.initForm(UserStoreModule.CurrentProject, ViewModeType.UPDATE);
    }
    saveClick() {
        return ProjectService.update(this.jtlForm.formModel).then(res => {
            UserStoreModule.SET_CURRENT_PROJECT(this.jtlForm.formModel);
            this.showMessage('保存成功！');
        }).catch(err => {
            throw err;
        });
    }
}

